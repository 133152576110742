<template>
    <DnSlider ref="slider" class="card-slider" :items="itemsExtended">
        <template v-slot:slide="{ item, index }">
            <ImageCard
                :ref="index === Math.floor(itemsExtended.length / 2) ? 'middle' : null"
                :item="item"
                :tabindex="index > (items.length - 1) ? '-1' : '0'"
                :aria-hidden="index > (items.length - 1) ? 'true' : 'false'"
                drag-to-scroll="ignore"
                draggable="false"
                class="card-slider__card"
                sizes="(min-width: 768px) 50vw, 100vw"
            />
        </template>
    </DnSlider>
</template>

<script>
import DnSlider from '@digitalnatives/slider';

export default {
    components: {
        DnSlider
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    computed: {
        itemsExtended() {
            const extendedArray = [...this.items, ...this.items];
            if (extendedArray.length % 2 === 0) {
                extendedArray.pop(); // Make sure there is always a 'middle' item by making it uneven if even
            }

            return extendedArray;
        }
    },
    mounted() {
        this.$nextTick(() => {
            const scrollContainer = this?.$refs?.slider?.$el?.querySelector('.slider__track');

            scrollContainer?.scrollTo({
                left: scrollContainer.scrollWidth / 2
            });

            setTimeout(() => {
                this.isReady = true;
            }, 500);
        });
    }
};
</script>

<style lang="less" src="./CardSlider.less"></style>
