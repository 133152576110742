<template>
    <figure
        v-if="service === SERVICE_YOUTUBE || SERVICE_VIMEO"
        class="video-embed"
    >
        <div class="video-embed__group">
            <div class="video-embed__inner">
                <button v-if="accessibleUrl" class="vimeo__accessible-switch" @click="switchVideo">
                    <div class="sr-only">{{ accessibleSwitchLabel }}</div>
                    <BaseIcon :icon="showAccessibleVideo ? 'visual' : 'no-visual'" />
                </button>
                <iframe
                    v-if="service === SERVICE_YOUTUBE"
                    ref="video"
                    :src="`https://www.youtube-nocookie.com/embed/${id}?modestbranding=1&rel=0&enablejsapi=1`"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    :aria-labelledby="`video-${id}`"
                    :loading="loading"
                    :title="videoTitle"
                    data-cursor-hidden
                />
                <iframe
                    v-else-if="service === SERVICE_VIMEO"
                    ref="video"
                    :src="`https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&color=f7f5f1&dnt=1`"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    :aria-labelledby="`video-${id}`"
                    :loading="loading"
                    :title="videoTitle"
                    data-cursor-hidden
                />
            </div>
            <p v-if="caption" :id="`video-${id}`" class="video-embed__caption">{{ caption }}</p>
        </div>
    </figure>
</template>

<script>
import Player from '@vimeo/player';

const SERVICE_YOUTUBE = 'youtube',
    SERVICE_VIMEO = 'vimeo',
    YTREGEX = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:youtube\.com|youtu.be)(?:\/(?:(?:.+)[?&]v=|embed\/|v\/)?)([\w-]+)(?:\S+)?$/i,
    VIMEOREGEX = /^https:\/\/vimeo\.com\/([0-9]+)$/i,
    COOKIE_ACCESSIBLE_VIDEO = 'showAccessibleVideos';

export default {
    props: {
        url: {
            type: String,
            required: true,
            default: ''
        },
        accessibleUrl: {
            type: String,
            default: ''
        },
        caption: {
            type: String,
            default: ''
        },
        videoTitle: {
            type: String,
            default: ''
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    setup() {
        const showAccessibleVideo = useCookie(COOKIE_ACCESSIBLE_VIDEO, {
            maxAge: 31536000000,
            secure: true,
            sameSite: 'lax'
        });

        return {
            showAccessibleVideo
        };
    },
    data() {
        return {
            SERVICE_VIMEO,
            SERVICE_YOUTUBE,
            player: null
        };
    },
    computed: {
        service() {
            return this.parsed.service;
        },
        id() {
            return this.parsed.id;
        },
        parsed() {
            return this.showAccessibleVideo ? this.parseUrl(this.accessibleUrl) : this.parseUrl(this.url);
        },
        accessibleSwitchLabel() {
            return this.showAccessibleVideo ? this.$t('video.no_audio_desc') : this.$t('video.audio_desc');
        }
    },
    mounted() {
        if (this.service === SERVICE_VIMEO) {
            this.player = new Player(this.$refs.video);

            this.player?.on('play', () => {
                /* eslint-disable camelcase */
                this.$gtm?.push({
                    event: 'video_start',
                    video_provider: 'vimeo',
                    video_title: this.videoTitle,
                    video_url: this.url
                });
                /* eslint-enable camelcase */
            });
        }
    },
    methods: {
        parseUrl(url) {
            const service = this.parseService(url);

            return {
                service,
                id: this.parseId(service, url)
            };
        },
        parseService(url) {
            if (url?.match(YTREGEX)) {
                return SERVICE_YOUTUBE;
            }

            if (url?.match(VIMEOREGEX)) {
                return SERVICE_VIMEO;
            }

            return null;
        },
        parseId(service, url) {
            switch (service) {
            case SERVICE_YOUTUBE: {
                const matches = url.match(YTREGEX);
                return matches[1];
            }
            case SERVICE_VIMEO: {
                const matches = url.match(VIMEOREGEX);
                return matches[1];
            }
            }

            return null;
        },
        switchVideo() {
            this.showAccessibleVideo = !this.showAccessibleVideo;
        }
    }
};

</script>

<style lang="less" src="./VideoEmbed.less"></style>
