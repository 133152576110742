<template>
    <div class="sticky flexible-sections">
        <slot name="before" />

        <template
            v-for="(flexibleSection, index) in filteredFlexibleSections"
            :key="`flexibleSection_${index}`"
        >
            <component
                :is="map[flexibleSection.__typename]"
                v-bind="flexibleSection"
            />
        </template>

        <slot name="after" />
    </div>
</template>

<script>
import { resolveComponent } from 'vue';

/* eslint-disable camelcase */
export const map = {
    flexibleSections_richText_BlockType: resolveComponent('FlexibleSectionsBlocksRichText'),
    flexibleSections_quote_BlockType: resolveComponent('FlexibleSectionsBlocksQuote'),
    flexibleSections_image_BlockType: resolveComponent('FlexibleSectionsBlocksImage'),
    flexibleSections_accordion_BlockType: resolveComponent('FlexibleSectionsBlocksAccordion'),
    flexibleSections_bigAccordion_BlockType: resolveComponent('FlexibleSectionsBlocksBigAccordion'),
    flexibleSections_bigLinks_BlockType: resolveComponent('FlexibleSectionsBlocksBigLinks'),
    flexibleSections_video_BlockType: resolveComponent('FlexibleSectionsBlocksVideo'),
    flexibleSections_postCards_BlockType: resolveComponent('FlexibleSectionsBlocksPostsBasicLatest'),
    flexibleSections_postCardsByTags_BlockType: resolveComponent('FlexibleSectionsBlocksPostsBasicByTags'),
    flexibleSections_postCardsManualSelect_BlockType: resolveComponent('FlexibleSectionsBlocksPostsBasicManualSelect'),
    flexibleSections_postsSliderLatest_BlockType: resolveComponent('FlexibleSectionsBlocksPostsSliderLatest'),
    flexibleSections_postsSliderByTags_BlockType: resolveComponent('FlexibleSectionsBlocksPostsSliderByTags'),
    flexibleSections_postsSliderManualSelect_BlockType: resolveComponent('FlexibleSectionsBlocksPostsSliderManualSelect'),
    flexibleSections_postsFeaturedLatest_BlockType: resolveComponent('FlexibleSectionsBlocksPostsFeaturedLatest'),
    flexibleSections_postsFeaturedByTags_BlockType: resolveComponent('FlexibleSectionsBlocksPostsFeaturedByTags'),
    flexibleSections_postsFeaturedManualSelect_BlockType: resolveComponent('FlexibleSectionsBlocksPostsFeaturedManualSelect'),
    flexibleSections_subsidies_BlockType: resolveComponent('FlexibleSectionsBlocksSubsidies'),
    flexibleSections_subsidyCategoryOverview_BlockType: resolveComponent('FlexibleSectionsBlocksSubsidyCategoryOverview'),
    flexibleSections_button_BlockType: resolveComponent('FlexibleSectionsBlocksButton'),
    flexibleSections_download_BlockType: resolveComponent('FlexibleSectionsBlocksDownload'),
    flexibleSections_helpCards_BlockType: resolveComponent('FlexibleSectionsBlocksHelpCards'),
    flexibleSections_teamMembers_BlockType: resolveComponent('FlexibleSectionsBlocksTeamMembers'),
    flexibleSections_typeformEmbed_BlockType: resolveComponent('FlexibleSectionsBlocksTypeformEmbed'),
    flexibleSections_deadlines_BlockType: resolveComponent('FlexibleSectionsBlocksDeadlines'),
    flexibleSections_anchor_BlockType: resolveComponent('FlexibleSectionsBlocksAnchor'),
};
/* eslint-enable camelcase */
</script>

<script setup>
const props = defineProps({
    sections: {
        type: Array,
        required: true,
        default: () => [],
    },
});

const { sections } = toRefs(props);

const shouldRender = (flexibleSection) => {
    if (!flexibleSection) {
        return false;
    }

    if (!flexibleSection.__typename || !map[flexibleSection.__typename]) {
        return false;
    }

    return true;
};

const filteredFlexibleSections = computed(() => {
    return sections.value.filter((flexibleSection) => shouldRender(flexibleSection));
});
</script>

<style lang="less">
.flexible-sections {
    grid-area: main;
    justify-self: center;
}
</style>
